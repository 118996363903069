import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
// import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";


// import * as serviceWorker from "./serviceWorker";
import { IntlProvider } from "react-intl";
import { Button } from "semantic-ui-react";
//import "semantic-ui-css/semantic.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import Sw from "./i18n/Sw";
import flatten from "flat";
import en from "./i18n/en";
import tzFlag from "./components/img/tanzania.png";
import ukFlag from "./components/img/united-kingdom.png"

const messages = {
  en: flatten(en),
  "Sw": flatten(Sw),
  
};

const Root = () => {
  const [locale, setLocale] = useState(navigator.language);
  const [mergedMessages, setMergedMessages] = useState(messages["Sw"]);

  const client = new ApolloClient({
  // uri: 'http://10.10.6.17:5000/graphql',
  // uri: 'http://10.10.6.74:5000/graphql',
  
// uri: 'https://10.20.0.6/v1/ovrsapi',
//   uri: 'https://ovrs-mafunzo.inec.go.tz/v1/ovrsapi',
//  uri: 'http://10.20.0.12:5050/v1/ovrsapi',

     uri: 'https://ovrs.inec.go.tz/v1/ovrsapi',
         //  uri: 'http://10.10.4.72:7575/v1/ovrsapi-new',

  //uri: 'http://localhost:5600/graphql',
  cache: new InMemoryCache(),
});

  useEffect(() => {
    // Merging english and current locale, avoid showing Text id if cannot look for the translate in locale file
    setMergedMessages(Object.assign({}, messages["Sw"], messages[locale]));
  }, [locale]);
  // console.log(flatten(Sw).toString().toLowerCase());
  return (
  
     <ApolloProvider client={client}  >
  
      
<IntlProvider  messages={mergedMessages}
      locale={locale}
      key={locale}
      defaultLocale="Sw">
      <App />
      </IntlProvider>

   </ApolloProvider>
   
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();












// const client = new ApolloClient({
//   uri: 'http://10.10.6.17:5000/graphql',
//   cache: new InMemoryCache(),
// });

// const el = document.getElementById("root");
// const root = createRoot(el);
// root.render(
//   <ApolloProvider client={client}>
//     <App />
//   </ApolloProvider>
// );
