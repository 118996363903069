

import { gql } from "@apollo/client";






// const  GET_REGIONS = gql`
// query GetRegions {
//   regions{
//       region_id,
//       region
//     }
// }
// `;
// export { GET_REGIONS };




// const  GET_REGIONS = gql`
// query GetRegions{
//   regionsByRouteDate{
//   region
//     region_id
//   }
  
// }
// `;
// export { GET_REGIONS };



const  GET_REGIONS = gql`
query GetRegions{
  councilsByRouteDate{
    council_id
    council
  }
  
}
`;
export { GET_REGIONS };

// NEW
  
const  GET_ALL_ROUTE_REGIONS = gql`
query GetRegions{
    routes {
        route_id
        route_name
        start_date
        end_date
        councils {
            council_id
            council
            region_id           
        }
    }
}

  

`;
export { GET_ALL_ROUTE_REGIONS };



const  GET_COUCILS = gql`
query  GetCoucils {
  coucils{
     council_id
    
    region_id
    council
    
  }
}

`;
export { GET_COUCILS };
  

const  GET_CONS= gql`
query  Getcons{
  constituencies{
    constituency_id,
    constituency,
    council_id
   },
  },
`;
export { GET_CONS };

const  GET_WARD= gql`
query  Getwards{
  wards{
    ward_id,
    ward,
    constituency_id
   },
  },
`;
export { GET_WARD };

const GET_KATA_CONS= gql`
  query 
   wardByCouncilID($council_id: Int){
wardByCouncilID(council_id: $council_id) {
  ward_id
  
  ward
  
  council_id
  township_authority_id
  constituency_id
  
}
}
`;
export { GET_KATA_CONS};

export const GET_MTAA_KATA= gql`
  query 
   villageByWardID($council_id: Int){
    villageByWardID(ward_id: $council_id) {
   village_id
   
    village
    
    villageormtaa
    
}
}
`;




const GET_VOTER = gql`
  query voterPersonalInfo($voter_id: ID, $birthYear: Int) {
    voterPersonalInfo(voter_id:$voter_id, birthYear:$birthYear) {
      voter_id
      ward
      firstname
      middlename
      surname
      othernames
      sex
      date_of_birth
      mobile_no
    }
  }
`;
export { GET_VOTER };





const GET_VOTERNIN = gql`
  query nida($national_id: ID!) {
    nida(national_id: $national_id) {
      personal_info_id
      national_id
      firstname
      middlename
      sex {
        sex_id
        sex_en
        sex_abbrv_en
        sex_sw
        sex_abbrv_sw
        is_active
      }
      surname
      othernames
      date_of_birth
      created_date
      last_update_date
       
    }
  }
`;
export { GET_VOTERNIN };


const  GET_REGCAT= gql`
query  GetRegcat{
  onlineRegCategories{
  online_registration_category_id
    online_registration_category_sw
    online_registration_category_en
    is_active
 
   
  }
}

`;
export { GET_REGCAT };

export  const SendVoterData1 = gql`
    mutation
createOnlineApplication ( 
     $registration_category_id: Int! , 
    $contact_mobile_no: String!,
$voter_id: ID!, 
$national_id: ID! ,
$registration_ward_id: Int 

 ) {
    createOnlineApplication(
    registration_category_id : $registration_category_id ,
    contact_mobile_no : $contact_mobile_no,
     voter_id : $voter_id ,
     national_id : $national_id , 
     registration_ward_id : $registration_ward_id 
     ) {
    application_reference_no  
    }
  }

`;


export  const SendVoterData = gql`
    mutation
createOnlineApplication ( 
     $registration_category_id: Int! , 
    $contact_mobile_no: String!,
$voter_id: ID!, 
$national_id: ID! ,
$registration_ward_id: Int ,
$registration_village_id: Int  ) {
    createOnlineApplication(
    registration_category_id : $registration_category_id ,
    contact_mobile_no : $contact_mobile_no,
     voter_id : $voter_id ,
     national_id : $national_id , 
     registration_ward_id : $registration_ward_id ,
     registration_village_id : $registration_village_id ) {
    application_reference_no

    
    
       
    }
  }
  `;











// import { gql } from "@apollo/client";

// const  GET_REGIONS = gql`
// query GetRegions {
//   regions{
//       region_id,
//       region
//     }
// }
// `;
// export { GET_REGIONS };
// const  GET_COUCILS = gql`
// query  GetCoucils {
//   coucils{
//      council_id
    
//     region_id
//     council
    
//   }
// }
// `;
// export { GET_COUCILS };
 
// const  GET_CONS= gql`
// query  Getcons{
//   constituencies{
//     constituency_id,
//     constituency,
//     council_id
//    },
//   },
// `;
// export { GET_CONS };

// const  GET_WARD= gql`
// query  Getwards{
//   wards{
//     ward_id,
//     ward,
//     constituency_id
//    },
//   },
// `;
// export { GET_WARD };

// const GET_KATA_CONS= gql`
//   query 
//    wardByCouncilID($council_id: Int){
// wardByCouncilID(council_id: $council_id) {
//   ward_id
  
//   ward
  
//   council_id
//   township_authority_id
//   constituency_id
  
// }
// }
// `;
// export { GET_KATA_CONS};

// export const GET_MTAA_KATA= gql`
//   query 
//    villageByWardID($council_id: Int){
//     villageByWardID(ward_id: $council_id) {
//    village_id
   
//     village
    
//     villageormtaa
    
// }
// }
// `;

// const GET_VOTER = gql`
//   query voterPersonalInfo($voter_id: ID, $birthYear: Int) {
//     voterPersonalInfo(voter_id:$voter_id, birthYear:$birthYear) {
//       voter_id
//       ward
//       firstname
//       middlename
//       surname
//       othernames
//       sex
//       date_of_birth
//       mobile_no
//     }
//   }
// `;
// export { GET_VOTER };

// const GET_VOTERNIN = gql`
//   query nida($national_id: ID!) {
//     nida(national_id: $national_id) {
//       personal_info_id
//       national_id
//       firstname
//       middlename
//       sex {
//       gender_id
//       gender_en
//       gender_abbrv_en
//       gender_sw
//       gender_abbrv_sw
//       is_active
//     }
//       surname
//       othernames
//       date_of_birth
//       created_date
//       last_update_date
       
//     }
//   }
// `;
// export { GET_VOTERNIN };


// const  GET_REGCAT= gql`
// query  GetRegcat{
//   onlineRegCategories{
//   online_registration_category_id
//     online_registration_category_sw
//     online_registration_category_en
//     is_active
 
   
//   }
// }
// `;
// export { GET_REGCAT };

// export  const SendVoterData1 = gql`
//     mutation
// createOnlineApplication ( 
//      $registration_category_id: Int! , 
//     $contact_mobile_no: String!,
// $voter_id: ID!, 
// $national_id: ID! ,
// $registration_ward_id: Int 

//  ) {
//     createOnlineApplication(
//     registration_category_id : $registration_category_id ,
//     contact_mobile_no : $contact_mobile_no,
//      voter_id : $voter_id ,
//      national_id : $national_id , 
//      registration_ward_id : $registration_ward_id 
//      ) {
//     application_reference_no  
//     }
//   }

// `;
// export  const SendVoterData = gql`
//     mutation
// createOnlineApplication ( 
//      $registration_category_id: Int! , 
//     $contact_mobile_no: String!,
// $voter_id: ID!, 
// $national_id: ID! ,
// $registration_ward_id: Int ,
// $registration_village_id: Int  ) {
//     createOnlineApplication(
//     registration_category_id : $registration_category_id ,
//     contact_mobile_no : $contact_mobile_no,
//      voter_id : $voter_id ,
//      national_id : $national_id , 
//      registration_ward_id : $registration_ward_id ,
//      registration_village_id : $registration_village_id ) {
//     application_reference_no

    
    
       
//     }
//   }
//   `;
